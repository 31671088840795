import React from "react";
import ReactDOM from "react-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import "./index.css";
import { Root } from "./Root";
import * as serviceWorker from "./serviceWorker";

import { initBackend } from "@discoverr/web/backend";

initBackend({
  apiHostname: process.env["REACT_APP_API"] as string,
});

LicenseInfo.setLicenseKey(
  "6e9ddeb2fb16eb1c66df54d530942283Tz03NTg0NyxFPTE3Mjc5NDEwNDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
