import React from "react";
import { Router, Routes, Route, Loadable } from "@discoverr/web/Router";
import { Bootstrap } from "@discoverr/web/Bootstrap";
import { SpaceAuthLoginPage } from "@discoverr/web/SpaceAuth";
import en from "@discoverr/web/languages/en";
import { useNavigate } from "@discoverr/web/Router";
import { getTheme, ThemeType } from "@discoverr/web/themeOld";

// Constants
const theme: any = getTheme(
  ThemeType.Light,
  {
    main: "#B8BD9C",
    contrastText: "#fff",
  },
  {
    main: "#122c3a",
  }
);

// Views
const LoadableApp = Loadable(React.lazy(() => import("./views/App")));

const LoadableBoxApp = Loadable(React.lazy(() => import("./views/BoxApp")));

// Components

const Login = () => {
  const navigate = useNavigate();
  return (
    <SpaceAuthLoginPage
      title="Imbox Cloud"
      subtitle="Sign in on the internal platform"
      image="https://imboxprotection.com/media/3ekgm2lj/imboxprotection-flagship.png"
      onSuccess={() => {
        navigate("/");
      }}
    />
  );
};

//
export const Root = () => {
  return (
    <Bootstrap
      titleTemplate="Imbox - %s"
      language={en}
      theme={theme}
      art={true}
      artConfig={{ primary: "#B8BD9C" }}
    >
      <Router>
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/*" element={<LoadableApp />} />
          <Route path="/box-app/*" element={<LoadableBoxApp />} />
        </Routes>
      </Router>
    </Bootstrap>
  );
};
